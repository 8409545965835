// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useMemo } from 'react';
import {
  GET_LIST,
  UPDATE_ITEM_DETAIL,
  UPDATE_CATEGORY_DETAIL,
  ADD_ITEM,
  ADD_CATEGORY,
  DELETE_ITEM,
  MOVE_CATEGORY,
  UPDATE_ITEM_POSITIONS,
  UPDATE_CATEGORY_POSITIONS,
  SET_ITEM_CATEGORY,
  MOVE_ITEM_BETWEEN_CATEGORIES_IN_CACHE ,
  SET_EDIT_ITEM,
  SET_EDIT_ITEM_ERROR_MESSAGE,
  SET_EDIT_CATEGORY,
  SET_EDIT_CATEGORY_ERROR_MESSAGE,
  SET_EDIT_PACKING_LIST,
  SET_EDIT_PACKING_LIST_ERROR_MESSAGE,
  UPDATE_PACKING_LIST,
  SET_PAGE,
  GET_CONTEXT,
  DELETE_CATEGORY_REMOTE,
  CREATE_COLLABORATION,
  DELETE_COLLABORATION,
  UPDATE_PUBLISHED_STATUS,
} from './queries'

import CommonPage from '../components/CommonPage';
import ItemListPage from '../components/ItemListPage';
import CategoryListPage from '../components/CategoryListPage';
import SettingPage from '../components/SettingPage';
import RootContext from './RootContext';
import createHandlers from '../createHandlers';

const ListApp = ({ client }) => {
  /*
  /* Mutations
   */
  // For local
  const [setPage] = useMutation(SET_PAGE)
  // For list
  const [updatePackingList] = useMutation(UPDATE_PACKING_LIST)
  const [setEditPackingList] = useMutation(SET_EDIT_PACKING_LIST)
  const [setEditPackingListErrorMessage] = useMutation(SET_EDIT_PACKING_LIST_ERROR_MESSAGE)
  const [updatePublishedStatus] = useMutation(UPDATE_PUBLISHED_STATUS)
  // For item
  const [updateItemDetail] = useMutation(UPDATE_ITEM_DETAIL)
  const [deleteItem] = useMutation(DELETE_ITEM)
  const [addItem] = useMutation(ADD_ITEM)
  const [setItemCategory] = useMutation(SET_ITEM_CATEGORY)
  const [moveItemBetweenCategoriesInCache] = useMutation(MOVE_ITEM_BETWEEN_CATEGORIES_IN_CACHE )
  const [updateItemPositions] = useMutation(UPDATE_ITEM_POSITIONS)
  const [setEditItem] = useMutation(SET_EDIT_ITEM)
  const [setEditItemErrorMessage] = useMutation(SET_EDIT_ITEM_ERROR_MESSAGE)
  // For category
  const [addCategory] = useMutation(ADD_CATEGORY)
  const [setEditCategory] = useMutation(SET_EDIT_CATEGORY)
  const [setEditCategoryErrorMessage] = useMutation(SET_EDIT_CATEGORY_ERROR_MESSAGE)
  const [updateCategoryDetail] = useMutation(UPDATE_CATEGORY_DETAIL);
  const [deleteCategory] = useMutation(DELETE_CATEGORY_REMOTE)
  const [updateCategoryPositions] = useMutation(UPDATE_CATEGORY_POSITIONS)
  const [moveCategory] = useMutation(MOVE_CATEGORY)
  // For collaboration
  const [createCollaboration] = useMutation(CREATE_COLLABORATION)
  const [deleteCollaboration] = useMutation(DELETE_COLLABORATION)

  const { loading: contextLoading, error: contextError, data: contextData } = useQuery(GET_CONTEXT);
  const { loading, error, data, refetch } =  useQuery(GET_LIST, { variables: {id: window.list_id}});

  const eventHandlers = useMemo(()=> (createHandlers(client, {
      setPage,
      refetch,
      setItemCategory,
      moveItemBetweenCategoriesInCache,
      updateItemPositions,
      updatePackingList,
      setEditPackingList,
      setEditPackingListErrorMessage,
      updateItemDetail,
      deleteItem,
      addItem,
      addCategory,
      setEditCategory,
      setEditCategoryErrorMessage,
      updateCategoryDetail,
      deleteCategory,
      updateCategoryPositions,
      moveCategory,
      setEditItem,
      setEditItemErrorMessage,
      createCollaboration,
      deleteCollaboration,
      updatePublishedStatus
    })
  ), [])

  if(loading || contextLoading) return <p>Loading...</p>
  if(error || contextError) return <p>{error.toString()}</p>;

  const context = contextData.context;
  const packingList = data.packingList;

  return <RootContext.Provider value={{
            packingList: packingList,
            context: context,
            ...eventHandlers,
          }}>
          <CommonPage />
          { (context.page == 'main')     && <ItemListPage /> }
          { (context.page == 'setting')  && <SettingPage /> }
          { (context.page == 'category') && <CategoryListPage /> }
        </RootContext.Provider>
}
export default ListApp
