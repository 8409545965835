import update from 'immutability-helper'
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { GET_LIST, GET_CONTEXT } from './containers/queries'

export default (csrfToken)=> {
  const listId = window.list_id
  const initialContext = {
    editItemErrorMessage: "",
    editItem: null,
    editCategoryErrorMessage: "",
    editCategory: null,
    editPackingListErrorMessage: "",
    editPackingList: null,
    editPage: 1,
    page: "main",
    __typename: "context"
  }
  const link = new HttpLink({
        uri: window.endpoint,
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': csrfToken
        }
  });
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link,
    cache,
    resolvers: {
      Query: {
        context: ()=> (initialContext)
      },
      Mutation: {
        setPage: (_, arg, ddd) => {
          let data = readContext()
          cache.writeQuery({ query: GET_CONTEXT, data: {
            context: {
              editPage: 1,
              page: arg.input.page,
              __typename: 'context'
            }
          }})
        },
        moveItemBetweenCategoriesInCache: (_, { input: { fromCategoryId, fromIndex: fromItemIndex, toCategoryId, toIndex: toItemIndex}}, ddd) => {
          const packingList = readList(listId)
          const fromCategoryIndex = findCategoryIndexById(packingList, fromCategoryId)
          const toCategoryIndex = findCategoryIndexById(packingList, toCategoryId)
          const targetItem = packingList.categories[fromCategoryIndex].items[fromItemIndex]

          // Delete targetItem from fromCategory.items
          let newPackingList = update(packingList,
            { categories: {[fromCategoryIndex]: { items: { $splice: [[fromItemIndex ,1]]}}}},
          )
          // Insert targetItem to toCategory.items
          newPackingList = update(newPackingList,
            { categories: {[toCategoryIndex]: { items: { $splice: [[toItemIndex ,0, targetItem]]}}}}
          )
          cache.writeQuery({ query: GET_LIST, data: { packingList: newPackingList }})
        },
        moveCategory: (_, arg, ddd) => {
          const packingList = readList(window.list_id)
          let fromIndex;
          packingList.categories.forEach((c, i)=> {
            if(c.id == arg.input.id) {
              fromIndex = i 
            }
          })
          const targetCategory = packingList.categories[fromIndex]
          let newPackingList = update(packingList, { categories: { $splice: [[fromIndex, 1]]}})
          newPackingList = update(newPackingList, { categories: { $splice: [[arg.input.index + 1, 0, targetCategory]]}})
          cache.writeQuery({ query: GET_LIST, data: { packingList: newPackingList }})
        },
        endEditItem: (_, arg, ddd) => {
          cache.writeData({ data: { context: { editItem: null }}})
        },
        setEditItemErrorMessage: (_, arg, ddd) => {
          let { input: { message } } = arg
          let c = cache.readQuery({query: GET_CONTEXT })
          const n = update(c, { context: { editItemErrorMessage: { $set: message }}})
          cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
        },
        setEditItem: (_, arg, ddd) => {
          if(!arg.input.id) {
            let { context } = cache.readQuery({query: GET_CONTEXT })
            const newContext = update(context, { editItem: { $set: null }})
            cache.writeQuery({query: GET_CONTEXT, data: { context: newContext  } })
            return
          }
          let data = cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
          let fromCategoryIndex;
          let fromItemIndex;
          data.packingList.categories.forEach((category, j) => {
            category.items.forEach((item, i) => {
              if(category.items[i].id == arg.input.id) {
                fromCategoryIndex = j
                fromItemIndex = i
              }
            })
          })
          const targetItem = data.packingList.categories[fromCategoryIndex].items[fromItemIndex]
          let c = cache.readQuery({query: GET_CONTEXT })
          const n = update(c, { context: { editItem: { $set: targetItem }}})
          cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
        },

        setEditCategoryErrorMessage: (_, {input: { message }}, ddd) => {
          let c = readContext()
          const n = update(c, { context: { editCategoryErrorMessage: { $set: message }}})
          cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
        },

        setEditCategory: (_, arg, ddd) => {
          if(!arg.input.id) {
            let c = cache.readQuery({query: GET_CONTEXT })
            const n = update(c, { context: { editCategory: { $set: null }}})
            cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
            return
          }
          let data = cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
          const targetCategory = data.packingList.categories.find((category) => category.id === arg.input.id)
          let c = cache.readQuery({query: GET_CONTEXT })
          const n = update(c, { context: { editCategory: { $set: targetCategory }}})
          cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
        },

        setEditPackingListErrorMessage: (_, {input: { message }}, ddd) => {
          let c = readContext()
          const n = update(c, { context: { editPackingListErrorMessage: { $set: message }}})
          cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
        },

        setEditPackingList: (_, arg, ddd) => {
          let c = cache.readQuery({query: GET_CONTEXT })
          if(!arg.input.id) {
            const n = update(c, { context: { editPackingList: { $set: null }}})
            cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
            return
          }
          let data = cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
          const n = update(c, { context: { editPackingList: { $set: data.packingList }}})
          cache.writeQuery({query: GET_CONTEXT, data: { context: n.context } })
        },
        moveItem: (_, arg, ddd) => {
          let data = cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
          const packingList = data.packingList
          const tmpItem = packingList.items[arg.input.hoverIndex]
          packingList.items[arg.input.hoverIndex] = packingList.items[arg.input.dragIndex]
          packingList.items[arg.input.dragIndex] = tmpItem
          cache.writeData({ data: { packingList: { items: packingList.items } }})
        }
      }
    }
  });

  const readList = (listId) => {
    let { packingList } = cache.readQuery({query: GET_LIST, variables: { id: listId } })
    return packingList
  }

  const findCategoryIndexById = (packingList, categoryId) => {
    return packingList.categories.findIndex((c) =>  c.id === categoryId)
  }

  const readContext = ()=> {
    let data = cache.readQuery({query: GET_CONTEXT })
    return data
  }

  return client
}
