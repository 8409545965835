import React from 'react';

const NewItemForm = ({onSubmit}) => {
  let input;
  return <form onSubmit={(e) => {
    e.preventDefault()
    onSubmit(input.value)
    input.value = ''
  }} >
    <div className='row'>
      <div className='input-group'>
        <input type='text' className='form-control' ref={node => { input = node }}></input>
        <button type='submit' className='px-4 btn btn-primary spec-add-button'><i className='icon bi-plus'/></button>
      </div>
    </div>
  </form>
}
export default NewItemForm
