import gql from "graphql-tag";

export const GET_TAGS = gql`
  query {
    tags {
      id
      name
    }
  }
`

export const GET_CONTEXT = gql`
  query {
    context @client {
      editPackingListErrorMessage
      editPackingList
      editCategoryErrorMessage
      editCategory
      editItemErrorMessage
      editItem
      editPage
      page
    }
  }
`

export const  GET_LIST = gql`
  query($id: ID!) {
    packingList(id: $id) {
      id
      name
      description
      emoji
      published
      isOwner
      tags {
        id
        name
      }
      defaultCategory {
        id
        name
      }
      categories {
        id
        name
        description
        items {
          id
          name
          description
          categoryId
          done
        }
      }
      collaboration {
        id
        mode
        token
      }
    }
  }
`

export const EDIT_ITEM = gql`
  query {
    editItem @client {
      id
      name
      description
    }
  }
`

export const EDIT_CATEGORY = gql`
  query {
    editCategory @client {
      id
      name
      description
    }
  }
`

export const EDIT_PACKING_LIST = gql`
  query {
    editPackingList @client {
      id
      name
      description
    }
  }
`

export const UPDATE_PACKING_LIST = gql`
  mutation($id: ID!, $name: String!, $description: String!, $emoji: String!) {
    updatePackingList(input: { id: $id, name: $name, description: $description, emoji: $emoji }) {
      packingList {
        id
        name
        description
        emoji
        published
      }
    }
  }
`

export const   UPDATE_ITEM_DETAIL = gql`
  mutation($id: ID!, $name: String, $description: String, $done: Boolean) {
    updateItemDetail(input: { id: $id, name: $name, description: $description, done: $done }) {
      item {
        id
        name
        done
      }
    }
  }
`

export const  UPDATE_CATEGORY_DETAIL = gql`
  mutation($id: ID!, $name: String!, $description: String!) {
    updateCategoryDetail(input: { id: $id, name: $name, description: $description }) {
      category {
        id
        name
      }
    }
  }
`

export const   UPDATE_ITEM_POSITIONS = gql`
  mutation($ids: [ID!]!) {
    updateItemPositions(input: { ids: $ids }) {
      items {
        id
      }
    }
  }
`

export const   UPDATE_CATEGORY_POSITIONS = gql`
  mutation($ids: [ID!]!) {
    updateCategoryPositions(input: { ids: $ids }) {
      categories {
        id
      }
    }
  }
`

export const   SET_PAGE = gql`
  mutation($page: String!) {
    setPage(input: {page: $page}) @client
  }
`

export const   MOVE_ITEM = gql`
  mutation($drag_index: String!, $hover_index: String!) {
    moveItem(input: { dragIndex: $drag_index, hoverIndex: $hover_index }) @client
  }
`

export const MOVE_CATEGORY = gql`
  mutation($id: ID!, $index: Number!) {
    moveCategory(input: { id: $id, index: $index }) @client
  }
`

export const   SET_ITEM_CATEGORY = gql`
  mutation($id: ID!, $category_id: ID!) {
    setItemCategory(input: {id: $id, categoryId: $category_id }) {
      item {
        id
      }
    }
  }
`

export const   MOVE_ITEM_BETWEEN_CATEGORIES_IN_CACHE = gql`
  mutation($id: ID!, $from_category_id: Number!, $from_index: Number!
                     $to_category_id: Number!, $to_index: Number!) {
    moveItemBetweenCategoriesInCache(input: { id: $id, fromCategoryId: $from_category_id, fromIndex: $from_index
                                      toCategoryId: $to_category_id, toIndex: $to_index }) @client
  }
`

export const   SET_EDIT_ITEM = gql`
  mutation($id: ID!) {
    setEditItem(input: { id: $id }) @client
  }
`

export const   SET_EDIT_ITEM_ERROR_MESSAGE = gql`
  mutation($message: String) {
    setEditItemErrorMessage(input: { message: $message }) @client
  }
`

export const   SET_EDIT_CATEGORY = gql`
  mutation($id: ID!) {
    setEditCategory(input: { id: $id }) @client
  }
`

export const   SET_EDIT_CATEGORY_ERROR_MESSAGE = gql`
  mutation($message: String) {
    setEditCategoryErrorMessage(input: { message: $message }) @client
  }
`

export const   SET_EDIT_PACKING_LIST = gql`
  mutation($id: ID) {
    setEditPackingList(input: { id: $id }) @client
  }
`

export const   SET_EDIT_PACKING_LIST_ERROR_MESSAGE = gql`
  mutation($message: String) {
    setEditPackingListErrorMessage(input: { message: $message }) @client
  }
`

export const ADD_ITEM = gql`
  mutation($list_id: ID!, $name: String!, $category_id: ID) {
    addItem(input: { listId: $list_id, name: $name, categoryId: $category_id }) {
      item {
        id
        name
      }
    }
  }
`

export const   ADD_CATEGORY = gql`
  mutation($list_id: ID!, $name: String!) {
    addCategory(input: { listId: $list_id, name: $name }) {
      category {
        id
      }
    }
  }
`

export const   DELETE_ITEM = gql`
  mutation($id: ID!) {
    deleteItem(input: { id: $id }) {
      item {
        id
      }
    }
  }
`

export const  DELETE_CATEGORY = gql`
  mutation($id: ID!) {
    deleteCategory(input: { id: $id }) @client
  }
`

export const  DELETE_CATEGORY_REMOTE = gql`
  mutation($id: ID!) {
    deleteCategory(input: { id: $id }) {
      category {
        id
      }
    }
  }
`

export const  CREATE_COLLABORATION = gql`
  mutation($list_id: ID!, $mode: String!) {
    createCollaboration(input: { listId: $list_id, mode: $mode }) {
      collaboration {
        id
        mode
        token
      }
    }
  }
`

export const  DELETE_COLLABORATION = gql`
  mutation($list_id: ID!, $mode: String!) {
    deleteCollaboration(input: { listId: $list_id, mode: $mode }) {
      collaboration {
        id
      }
    }
  }
`

export const  UPDATE_PUBLISHED_STATUS = gql`
  mutation($id: ID!, $published: Boolean!) {
    updatePublishedStatus(input: { id: $id, published: $published }) {
      packingList {
        id
      }
    }
  }
`
