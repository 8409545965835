import React, { useRef } from 'react';

const CategoryEditor = (props) => {
  const refName = useRef(null)
  const refDesc = useRef(null)
  const onSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(refName.current.value, refDesc.current.value)
  }
  const onCancel = (e) => {
    e.preventDefault()
    props.onClose()
  }
  return (
      <div className="modal fade show category-editor" aria-modal="true" style={{display: 'block'}} tabIndex="-1" role="dialog">
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={onSubmit}>
              <div className="modal-header">
                <button type="button" className="btn-close" onClick={onCancel}></button>
              </div>
              <div className="modal-body">
                <div  className="mb-3">
                  <label htmlFor='input-category-name' className='form-label'>名前</label>
                  <input type='text' id='input-category-name' className='form-control' ref={refName} defaultValue={props.category.name}></input>
                  { props.errorMessage ? <p className="text-danger">{props.errorMessage}</p> : null }
                </div>
                <div  className="mb-3">
                  <label htmlFor='input-category-description' className='form-label'>説明</label>
                  <textarea type='text' id='input-category-description' className='form-control' ref={refDesc} defaultValue={props.category.description}></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={onCancel}>キャンセル</button>
                <button type="submit" className="btn btn-primary">更新</button>
              </div>
            </form>
          </div>
        </div>
    </div>
  )
}
export default CategoryEditor
