import React, { useContext, useRef } from 'react';
import ItemList from '../components/ItemList';
import EditableText from '../components/EditableText';
import ListEditor from '../components/ListEditor';
import RootContext from '../containers/RootContext'
import PageLink from '../components/PageLInk'
import EditLink from '../components/EditLink'
import SimpleFormat from './SimpleFormat'
import ConfirmationDialog from '../components/ConfirmationDialog'
import { Emoji } from 'emoji-mart'

const ItemListPage = ()=> {
  const {
    context,
    packingList,
    onChangeCheckbox,
    onSetCategory,
    onEditItem,
    onEditCategory,
    onDeleteItem,
    onSubmitItem,
    onSubmitNewItem,
    onEndEditItem,
    onSetPage,
    onSubmitList,
    onCloseListEditor,
    onEditPackingList,
    onSubmitWritableCollaboration,
  } = useContext(RootContext)
  const ref = useRef()
  const deleteItemHandler = (item) => {
    ref.current.show(`${item.name}を削除しますか？`).then((str) => {
      onDeleteItem(item.id)
    }).catch(()=>{})
  }

  return (
    <div>
      <div className='row'>
        <div className='col'>
          { packingList.published ? <span className="badge bg-secondary mb-3">公開中</span>  : '' }
        </div>
        <div className='col'>
          <div className='float-end text-end'>
            <PageLink pageName='category' onClick={onSetPage}>カテゴリー編集</PageLink>{" / "}
            {packingList.isOwner ? (<PageLink pageName='setting' onClick={onSetPage}>設定</PageLink>) : null }
          </div>
        </div>
      </div>

      <div className='row mt-2'>
        <div className='col-10 d-flex justify-content-start'>
          <h1 className='fs-5 fw-normal'><Emoji emoji={packingList.emoji} set='apple' size={24} /> {packingList.name}</h1>
        </div>
        <div className='col-2 d-flex justify-content-end'>
          <EditLink onClick={onEditPackingList}/>
        </div>
      </div>
      <div className='mt-2 small'>
        { packingList.description !== '' ? <span><SimpleFormat keyPrefix='ps-desc-' className='mt-2'>{packingList.description}</SimpleFormat></span> : '' }
      </div>

      <ItemList defaultCategory={packingList.defaultCategory}
        categories={packingList.categories}
        {...{
          onChangeCheckbox,
          onSetCategory,
          onEditItem,
          onEditCategory,
          onDeleteItem: deleteItemHandler,
          onSubmitNewItem,
      }}/>

      <ConfirmationDialog ref={ref} />

      {!!context && context.editItem ? (
        <EditableText
          item={context.editItem}
          errorMessage={context.editItemErrorMessage}
          onSubmit={onSubmitItem}
          onClose={onEndEditItem}
        />
       ) : (null)}
      {!!context && context.editPackingList ? (
        <ListEditor
          packingList={context.editPackingList}
          errorMessage={context.editPackingListErrorMessage}
          onSubmit={onSubmitList}
          onSubmitNewItem={onSubmitNewItem}
          onClose={onCloseListEditor}
          onSubmitWritableCollaboration={onSubmitWritableCollaboration}
        />
      ) : (null)}

      <div className='row mt-4 mb-4'>
        <div className='col-6 d-flex justify-content-start'>
          <a className="btn btn-secondary" href="/my/lists">戻る</a>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          <a className="btn btn-primary" rel="nofollow" data-method="post" href={`/my/lists?copy_of=${packingList.id}`}>リストを複製</a>
        </div>
      </div>
    </div>
  )
}
export default ItemListPage
