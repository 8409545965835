import React, { useContext } from 'react';

interface MessageLinkEvent extends React.MouseEvent<HTMLAnchorElement, MouseEvent> {
    target: HTMLLinkElement;
}

export default  (props)=> {
  const onClick = (event: MessageLinkEvent)=> {
    event.preventDefault()
    props.onClick(props.pageName)
  }
  return <a href='' onClick={onClick}>{props.children}</a>
}
