import React, { useContext, useRef } from 'react'
import RootContext from '../containers/RootContext'
import CategoryList from './CategoryList'
import NewItemForm from './NewItemForm';
import PageLink from '../components/PageLInk'
import ConfirmationDialog from '../components/ConfirmationDialog'

const CategoryListPage = ()=> {
  const { 
    packingList,
    onEditCategory,
    onSetPage,
    onDeleteCategoryLocal,
    onSubmitNewCategory,
    onMoveCategory,
  } = useContext(RootContext)

  const ref = useRef()
  const deleteItemHandler = (category) => {
    let message = `グループ "${category.name}" を削除しますか？`
    if(category.items.length >= 1) {
      message = "このグループに含まれているアイテムは削除されます。" + message
    }
    ref.current.show(message).then((str) => {
      onDeleteCategoryLocal(category.id)
    }).catch(()=>{})
  }

  return (
    <div id='category-list-page'>
      <PageLink pageName='main' onClick={onSetPage}><i className='me-1 mb-3 icon bi-chevron-left' />リストに戻る</PageLink>

      <ConfirmationDialog ref={ref} />
      <div className='mt-3'>
      <NewItemForm onSubmit={onSubmitNewCategory} />
      </div>

      <CategoryList
        onSetCategory={onMoveCategory}
        defaultCategory={packingList.defaultCategory} 
        categories={packingList.categories} 
        onEditCategory={onEditCategory}
        onDeleteCategory={deleteItemHandler}
      />
    </div>
  )
}
export default CategoryListPage
