import React, { Fragment, useState } from 'react'
import SimpleFormat from './SimpleFormat'
import { Emoji, Picker } from 'emoji-mart'

const EmojiSelect = (props)=> {
  const [isShow, setIsShow] = useState(false)
  const onSelect = (emoji) => {
    props.onSelect(emoji)
    setIsShow(false)
  }

  return (
    <Fragment>
      <div>
        <Emoji emoji={props.emoji} set='apple' size={32} onClick={e => setIsShow(!isShow) }/>
      </div>
      { isShow ? (
          <Picker set='apple' perLine={8} onSelect={onSelect} />
        ) : null
      }
    </Fragment>
  )
}
export default EmojiSelect
