import React from 'react';
import ReactDOM from 'react-dom';
import ListApp from './containers/ListApp';
import createApolloClient from './createApolloClient';
import { ApolloProvider } from 'react-apollo';

document.addEventListener('DOMContentLoaded', () => {
  const listApp = document.getElementById('list-app');
  if(listApp) {
    const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    const client = createApolloClient(csrfToken)
    ReactDOM.render(
      <ApolloProvider client={client}>
        <ListApp client={client}/>
      </ApolloProvider>,
      document.getElementById('list-app')
    )
  }
})
