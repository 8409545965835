import React, { Fragment, useContext } from 'react'
import RootContext from '../containers/RootContext'
import PageLink from '../components/PageLInk'
import EmojiSelect from '../components/EmojiSelect'

const SettingPage = ()=> {
  const {
    packingList,
    onSetPage,
    onSubmitWritableCollaboration,
    onDeleteWritableCollaboration,
    onChagePublishedStatus,
    onSelectIcon
  } = useContext(RootContext)

  const onSelectEmoji = (emoji) => {
    onSelectIcon(emoji)
  }

  const onClickCreateWritable = (e) => {
    e.preventDefault()
    onSubmitWritableCollaboration()
  }

  const onClickDeleteWritable = (e) => {
    e.preventDefault()
    onDeleteWritableCollaboration()
  }

  const onChangePublished = (e) => {
    e.preventDefault()
    onChagePublishedStatus(e.target.checked)
  }

  if(!packingList.isOwner) {
    return (<div></div>)
  }

  const sharedUrl = packingList.collaboration ? `${window.url_base}${'/my/lists/'}${packingList.collaboration.token}` : null

  return (
    <div>
      <PageLink pageName='main' onClick={onSetPage}><i className='me-1 icon bi-chevron-left' />リストに戻る</PageLink>

      <div className='card mt-3'>
        <div className='card-body'>
          <div>
          <h3 className='fs-5 fw-normal'>アイコン</h3>
          </div>
          <EmojiSelect onSelect={ onSelectEmoji } emoji={packingList.emoji} />
        </div>
      </div>

      <div className='card mt-3'>
        <div className='card-body'>
          <h3 className='fs-5 fw-normal'>共有設定</h3>
          <p className='mt-3'>持ち物リストを他のユーザが変更できるようにします。
             URLを知っているユーザはこのリストを変更できるようになります。</p>
          <div className='row'>
            {packingList.collaboration ? (
              <Fragment>
                <div className='col-9'>
                  <div className='input-group' >
                    <span className='input-group-text'>URL</span>
                    <input className='form-control shared-url' readOnly type='text' value={sharedUrl} />
                  </div>
                </div>
                <div className='col-3' >
                  <a href='' className='form-control btn btn-primary unshare-link' onClick={onClickDeleteWritable}>解除</a>
                </div>
              </Fragment>
            ) : (<div>
                <a href='' className='btn btn-primary share-link' disabled onClick={onClickCreateWritable}>編集共有</a>
              </div>)
            }
          </div>

        </div>
      </div>

      <div className='card mt-3'>
        <div className='card-body'>
          <h3 className='fs-5 fw-normal'>公開設定</h3>
          <p className='mt-3'>チェックを入れると「みんなのリスト」に表示され、他のユーザが参照できるようになります。<br />役立ちそうな持ち物リストができたら、みんなが使えるように共有してみましょう。</p>
          <div className="form-group form-check mt-4">
            <input className="form-check-input" type='checkbox' id="published" onChange={onChangePublished} checked={packingList.published}></input>
            <label htmlFor="published" >公開する</label>
          </div>
        </div>
      </div>

      <div className='card mt-3'>
        <div className='card-body'>
          <h3 className='fs-5 fw-normal'>リスト削除</h3>
          <p className='mt-3'>この持ち物リストを削除します。</p>
          <div className="row">
            <div className='form-row mt-4'>
              <div className='col-9 input-group' >
                <a className="btn btn-danger" data-confirm="削除しますか？" data-method='delete' href={`/my/lists/${packingList.id}`}>リストを削除する</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SettingPage
