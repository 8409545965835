import { GET_LIST, GET_CONTEXT } from './containers/queries'

export default (client, mutations) => {
  const m = mutations

  return {
    onChangeCheckbox: (id, done) => {
      const { context } = client.cache.readQuery({query: GET_CONTEXT })
      m.updateItemDetail({ variables: { id: id, done: done }}).then(()=>{
        m.refetch()
      })
    },
    onSetCategory: ({id, fromCategoryId, fromIndex, toCategoryId, toIndex}) => {
      m.moveItemBetweenCategoriesInCache({ variables: { id,
                                           from_category_id: fromCategoryId, from_index: fromIndex,
                                           to_category_id: toCategoryId, to_index: toIndex }}).then(()=> {
        const { packingList } = client.cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
        m.setItemCategory({ variables: { id: id, category_id: toCategoryId} })
        const toCategory = packingList.categories.find((category)=> category.id == toCategoryId)
        m.updateItemPositions({ variables: { ids: toCategory.items.map((item)=> item.id) } })
      })
    },
    onMoveCategory: (args) => {
      m.moveCategory({ variables: { id: args.id, index: args.toIndex }}).then(() => {
        const { packingList } = client.cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
        const ids = packingList.categories.map((c)=> c.id).filter((id)=>{
          return id != packingList.defaultCategory.id
        })
        m.updateCategoryPositions({ variables: { packing_list_id: packingList.id, ids: ids} }).then(()=> {
          m.refetch()
        })
      })
    },
    onDeleteItem: (id) => {
      m.deleteItem({ variables: { id: id } }).then(() => {
        m.refetch()
      })
    },
    onSubmitItem: (name, description)=> {
      if(name.length == 0) {
        m.setEditItemErrorMessage({ variables: { message: "名前を入力してください" } })
        return
      }
      const { context } = client.cache.readQuery({query: GET_CONTEXT })
      m.updateItemDetail({ variables: { id: context.editItem.id, name, description }}).then(()=>{
        m.setEditItem({ variables: { id: null } })
        m.setEditItemErrorMessage({ variables: { message: null } })
        m.refetch()
      })
    },
    onSubmitCategory: (name, description)=> {
      if(name.length == 0) {
        m.setEditCategoryErrorMessage({ variables: { message: "名前を入力してください" } })
        return
      }
      const { context } = client.cache.readQuery({query: GET_CONTEXT })
      m.updateCategoryDetail({ variables: { id: context.editCategory.id, name, description }}).then(()=>{
        m.setEditCategory({ variables: { id: null } })
        m.setEditCategoryErrorMessage({ variables: { message: null } })
        m.refetch()
      })
    },
    onSubmitList: (name, description)=> {
      if(name.length == 0) {
        m.setEditPackingListErrorMessage({ variables: { message: "名前を入力してください" } })
        return
      }
      const { packingList } = client.cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
      const variables = {
        id: window.list_id,
        name: name,
        description: description,
        emoji: packingList.emoji
      }
      m.updatePackingList({ variables: variables }).then(()=>{
        const { context } = client.cache.readQuery({query: GET_CONTEXT })
        const request_tags = context.editPackingList.tags.map((t)=> ({ id: t.id, name: t.name }))
        m.refetch()
        m.setEditPackingList({ variables: { id: null } })
        m.setEditPackingListErrorMessage({ variables: { message: null } })
      })
    },
    onDeleteWritableCollaboration: ()=> {
      m.deleteCollaboration({ variables: { list_id: window.list_id, mode: 'write' } }).then(() => m.refetch() )
    },
    onSubmitWritableCollaboration: (e)=> {
      m.createCollaboration({ variables: { list_id: window.list_id, mode: 'write' } }).then(() => m.refetch() )
    },

    onSelectIcon: (emoji)=> {
      const { packingList } = client.cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
      const variables = {
        id: window.list_id,
        name: packingList.name,
        description: packingList.description,
        emoji: emoji.colons
      }
      m.updatePackingList({ variables: variables }).then(()=>{
        m.refetch()
      })
    },

    onEditPackingList: ()=> {
      m.setEditPackingList({ variables: { id: window.list_id } })
      m.setEditPackingListErrorMessage({ variables: { message: null } })
    },
    onCloseListEditor: ()=> {
      m.setEditPackingList({ variables: { id: null } })
    },
    onSubmitNewItem: (category_id, name) => {
      if(name.length == 0) {
        console.log("空にできません")
        return
      }
      m.addItem({ variables: { list_id: window.list_id, name: name, category_id: category_id } }).then(() => {
        m.refetch()
      })
    },
    onChagePublishedStatus: (isPublished) => {
      m.updatePublishedStatus({ variables: { id: window.list_id, published: isPublished } }).then(() => {
        m.refetch()
      })
    },
    onSubmitNewCategory: (name) => {
      if(name.length == 0) {
        console.log("空にできません")
        return
      }
      m.addCategory({ variables: { list_id: window.list_id, name: name } }).then(() => {
        m.refetch()
      })
    },
    onEditItem: (id) => {
      m.setEditItem({ variables: { id: id } })
      m.setEditItemErrorMessage({ variables: { message: null } })
    },
    onEndEditItem: ()=> {
      m.setEditItem({ variables: { id: null } })
      m.setEditItemErrorMessage({ variables: { message: null } })
    },
    onSetPage: (page)=> {
      m.setPage({ variables: { page: page }})
    },
    onEditCategory: (id) => {
      m.setEditCategory({ variables: { id: id } })
      m.setEditCategoryErrorMessage({ variables: { message: null } })
    },
    onCloseEditCategory: ()=> {
      m.setEditCategory({ variables: { id: null } })
    },
    onDeleteCategoryLocal: (id) => {
      const { packingList } = client.cache.readQuery({query: GET_LIST, variables: { id: window.list_id } })
      const category = packingList.categories.find((c)=> c.id === id)
      m.deleteCategory({ variables: { id: id }}).then(()=> {
        m.refetch()
      })
    }
  }
}
