import React, { useImperativeHandle, useState, createContext, useContext, forwardRef } from 'react';

const InnerConfirmationDialog = (props, ref) => {
  const [showable, setShowable] = useState(false);
  const [message, setMessage] = useState('');
  const [handleOk, setHandleOk] = useState(()=>{});
  const [handleCancel, setHandleCancel] = useState(()=>{});

  useImperativeHandle(ref, () => ({
    show: async (message)=> {
      setShowable(true)
      setMessage(message)
      await new Promise((resolve, reject) => {

        setHandleOk(()=> () => {
          console.log('resolve')
          setShowable(false)
          resolve()
        })

        setHandleCancel(()=> () => {
          console.log('cancel')
          setShowable(false)
          reject()
        })
      })
    }
  }))

  return (
    <div>
      { showable ? (<div className="modal fade show item-editor" aria-modal="true" style={{display: 'block'}} tabIndex="-1" role="dialog">
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" onClick={handleCancel}>
              </button>
            </div>
            <div className="modal-body">
              <div  className="mb-3">
                {message}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => { handleCancel() }}>キャンセル</button>
              <button type="button" className="btn btn-primary" onClick={() => { handleOk() }}>削除</button>
            </div>
          </div>
        </div>
      </div>) : null }
    </div>
  )
}

const ConfirmationDialog = forwardRef(InnerConfirmationDialog)
export default ConfirmationDialog
