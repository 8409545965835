import React, { useRef } from 'react'
import { Draggable } from "react-beautiful-dnd";

const Category = ({ item, index, onChangeCheckbox, onDelete, onEditItem }) => {
  const ref = useRef(null)
  return (
    <Draggable draggableId={item.id} index={index}>
      {provided => (
        <div 
          style={provided.draggableProps.style}
          ref={provided.innerRef}
          {...provided.draggableProps}
          key={item.id}
          className='list-group-item'>
          <span>{item.name}</span>

          <span className='float-end'>
          <a className='edit-category-link'
            href='#'
            onClick={(e) => {
              e.preventDefault()
              onEditItem(item.id)
            }}><i className="icon bi-pencil pe-3" /></a>
          <a className='delete-category-link'
            href='#'
            onClick={(e) => {
              e.preventDefault()
              onDelete(item)
            }}><i className="icon bi-trash pe-0" /></a>
          <span {...provided.dragHandleProps} >
            <i  className='border p-1 icon bi-list ms-3' />
          </span>
          </span>
        </div>
      )}
    </Draggable>
  )
}
export default Category
