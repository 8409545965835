import React, { Fragment, useState, useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Item from './Item'
import SimpleFormat from './SimpleFormat'
import NewItemForm from '../components/NewItemForm';

const ItemList = ({ items, categories, defaultCategory, onSubmitNewItem, onChangeCheckbox, onDeleteItem, onEditItem, onEditCategory, onSetCategory }) => {
  const onDragEnd = (result) => {
    if(!result.destination) {
      return 
    }
    const from = {
      id: result.draggableId,
      fromCategoryId: result.source.droppableId,
      fromIndex: result.source.index,
      toCategoryId: result.destination.droppableId,
      toIndex: result.destination.index,
    }
    onSetCategory(from)
  }

  const onClickEditCategory = (category, e)=> {
    e.preventDefault()
    onEditCategory(category.id)
  }

  return (<DragDropContext onDragEnd={onDragEnd}>
    {categories.map(category => {
      return (<div key={category.id}>

      <Droppable droppableId={category.id} key={category.id}>
        {provided => (
          <Fragment>
          <div className='card mt-2' id={`category-item-list-${category.id}`}>
            { category.id != defaultCategory.id ? (

                <div className='card-header bg-light'>
                  <div className='row'>
                    <div className='col d-flex justify-content-start'>
                      <h2 className='fs-6 mb-0 lh-base'>
                        {category.name}
                      </h2>
                    </div>
                    <div className='col d-flex justify-content-end'>
                      <a className='edit-category-link'
                          href='' onClick={onClickEditCategory.bind(this, category)}>
                        <i className="icon bi-pencil fs-6 px-1" />
                      </a>
                    </div>
                  </div>

                  { category.description && category.description != '' ? (
                      <p className='card-text small'>
                        <SimpleFormat keyPrefix={`c-d-${category.id}`}>{category.description}</SimpleFormat>
                      </p>
                    ) : (
                      null
                    )
                  }
                </div>
              ) : null
            }
            {/* TODO: 切り出したい  */}
            {/* アイテム部分  */}
            <div ref={provided.innerRef} {...provided.droppbleProps} className={(category.items.length == 0) ? 'mt-0' : 'list-group list-group-flush mt-0'}>
              {category.items.map((item, index) => <Item
                  key={item.id}
                  index={index}
                  item={item}
                  onChangeCheckbox={onChangeCheckbox}
                  onDeleteItem={onDeleteItem}
                  onEditItem={onEditItem}>
                </Item>
              )}
              {provided.placeholder}
            </div>

            <div className='m-2'>
              <NewItemForm onSubmit={(name) => {
                onSubmitNewItem(category.id, name)
              }} />
            </div>
          </div>
        </Fragment>
        )}
      </Droppable>
      </div>
      )
      }
    )}
  </DragDropContext>)
}
export default ItemList
