import React, { useRef } from 'react'
import { Draggable } from "react-beautiful-dnd";
import SimpleFormat from './SimpleFormat'

const ItemTypes = {
  CARD: 'card',
}

const Item = ({ item, index, onChangeCheckbox, onDeleteItem, onEditItem,  }) => {
  const ref = useRef(null)
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided)=> (

        <div className='list-group-item'
             style={provided.draggableProps.style}
             id={`item-${item.id}`}
             key={item.id}
             ref={provided.innerRef}
             {...provided.draggableProps} >

            <div className='row d-flex flex-row align-items-center'>
              <div className='col-8 pe-0'
                onClick={() => {
                  onChangeCheckbox(item.id, !item.done)
                }}>
                <div className='form-check mb-0 me-2 d-flex align-items-center'>
                  <input className='form-check-input'
                    style={{"minWidth": '16px'}}
                    type='checkbox'
                    id={item.id}
                    checked={item.done}
                    onChange={() => {
                      onChangeCheckbox(item.id, !item.done)
                    }}>
                  </input>
                  <label id={`item-label-${item.id}`}
                    className='form-check-label pt-1 ps-2'
                    htmlFor={item.id}>
                    {item.name}
                    {item.description && item.description.length != 0 ? (<div className='small text-break'><SimpleFormat>{item.description}</SimpleFormat></div>) : null }
                  </label>
                </div>
              </div>

              <div className='col-4 ps-0 text-end'>
                <a className='edit-item-link'
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    onEditItem(item.id)
                  }}><i className="icon bi-pencil me-3" /></a>
                <a className='delete-item-link'
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    onDeleteItem(item)
                  }}><i className="icon bi-trash me-3" /></a>
                <span {...provided.dragHandleProps} >
                  <i  className='border icon bi-list p-1' />
                </span>
              </div>

            </div>

        </div>

      )}
    </Draggable>
  )
}
export default Item
