import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Category from './Category'

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

const ItemTypes = {
  CARD: 'card',
}

const CategoryList = ({ defaultCategory, categories, onEditCategory, onDeleteCategory, onSetCategory }) => {
  const onDragEnd = (result) => {
    const from = {
      id: result.draggableId,
      fromListId: result.source.droppableId,
      fromIndex: result.source.index,
      toListId: result.destination.droppableId,
      toIndex: result.destination.index,
    }
    onSetCategory(from)
  }
  const cs = categories.filter((category)=> category.id != defaultCategory.id)

  return (
  <div>
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={'1'} key={1}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppbleProps} className='list-group mt-2' >
          {cs.map((category, index) => {
            return (<Category
              onEditItem={onEditCategory}
              onDelete={onDeleteCategory}
              index={index} key={category.id} item={category}/>)
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
  </div>
  )
}
export default CategoryList