import React, { useContext } from 'react'
import RootContext from '../containers/RootContext'
import CategoryEditor from './CategoryEditor'

const CommonPage = (props)=> {
  const { 
    context,
    onSubmitCategory,
    onCloseEditCategory
  } = useContext(RootContext)
  return (
    <div>
      {context && context.editCategory ? <CategoryEditor
        category={context.editCategory}
        errorMessage={context.editCategoryErrorMessage}
        onSubmit={onSubmitCategory}
        onClose={onCloseEditCategory}
      /> : ""}

    </div>
  )
}
export default CommonPage 